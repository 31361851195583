import React from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import { Icon } from '../Icons';
import styles from './StyledModal.module.css';

interface Props {
  children?: React.ReactNode;
  className?: string;
  isOpen: boolean;
  backButton?: boolean;
  closeModal?: () => void;
  title?: string;
  loading?: boolean;
}

export const StyledModal = ({
  children,
  isOpen,
  className,
  closeModal,
  backButton,
  title,
  loading,
  ...props
}: Props) => {
  const renderButton = () => {
    const buttonClass = cx('absolute bg-transparent bn pa0 top-0 pointer', styles.buttonClass, {
      'right-0': !backButton,
      'left-0': backButton,
    });
    const icon = backButton ? 'SvgIconBack' : 'SvgIconClose';
    const iconAction = backButton ? 'Voltar' : 'Fechar diálogo';
    return (
      <button
        className={buttonClass}
        onClick={closeModal}
        title={iconAction}
        aria-label={iconAction}
        disabled={loading}
      >
        <Icon name={icon} />
      </button>
    );
  };
  const modalMain = cx(
    'h-100 h-auto-ns w-100 w-auto-ns overflow-auto bg-white relative br0 br4-ns br4-m br4-l trueno',
    styles.ph41,
    className,
  );
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(84, 87, 95, 0.6)',
    },
  };
  const root = cx(
    'relative absolute-ns h-100 h-auto-ns flex justify-center items-center',
    className,
    styles.containerModal,
  );
  return (
    <Modal
      style={customStyles}
      className={root}
      isOpen={isOpen}
      contentLabel={title}
      shouldCloseOnOverlayClick
      onRequestClose={!loading ? closeModal : undefined}
      ariaHideApp={false}
      role="dialog"
      aria-modal="true"
      {...props}
    >
      <div className={modalMain}>
        {renderButton()}
        {children}
      </div>
    </Modal>
  );
};
